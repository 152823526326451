import Vue from 'vue';
import Router from 'vue-router';
import Cookies from 'js-cookie';
import { getWxUserByCode, authRedirect, getBySN } from '@/api/h5';
import { Toast } from 'vant';

// console.log(t.router, '===');

Vue.use(Router);

const routes = [
  {
    path: '/',
    component: () => import('@/view/index'),
    name: 'Index',
    meta: {
      title: '首页'
    }  
  },
  {
    path: '/user',
    component: () => import('@/view/user/index'),
    name: 'user',
    meta: {
      title: '个人中心'
    }
  },
  {
    path: '/helper',
    component: () => import('@/view/helper/index'),
    name: 'helper',
    meta: {
      title: '家长帮助'
    }
  },
  {
    path: '/helper/detail',
    component: () => import('@/view/helper/detail'),
    name: 'helperDetaail',
    meta: {
      title: '家长帮助详情'
    }
  },
  {
    path: '/userList',
    component: () => import('@/view/user/list'),
    name: 'userList',
    meta: {
      title: '用户管理'
    }
  },
  {
    path: '/user/add',
    component: () => import('@/view/user/add'),
    name: 'userAdd',
    meta: {
      title: '创建硬件用户'
    }
  },
  {
    path: '/goods',
    component: () => import('@/view/goods/index'),
    name: 'goods',
    meta: {
      title: '积分兑换'
    }
  },
  {
    path: '/checkList',
    component: () => import('@/view/checkLIst'),
    name: 'checkList',
    meta: {
      title: '检测详情'
    }
  },
  {
    path: '/goodsList',
    component: () => import('@/view/goods/list'),
    name: 'goodsList',
    meta: {
      title: '兑换列表'
    }
  },
  // bindDevice?sn=XS202409302491
  { 
    path: '/bindDevice',
    redirect: '/deviceBind'
  },
  {
    path: '/deviceBind',
    component: () => import('@/view/device/bindv2'),
    name: 'deviceBind',
    meta: {
      title: '绑定设备'
    }
  },
  // 二期绑定设备 调试
  {
    path: '/device/bind',
    redirect: '/deviceBind'
    // component: () => import('@/view/device/bindv2'),
    // name: 'deviceBindNew',
    // meta: {
    //   title: '绑定设备'
    // }
  },
  {
    path: '/device',
    component: () => import('@/view/device/index'),
    name: 'device',
    meta: {
      title: '设备列表'
    }
  },
  {
    path: '/rent/list',
    component: () => import('@/view/rent/list'),
    name: 'rentList',
    meta: {
      title: '我的订单'
    }
  },
  {
    path: '/rent/detail',
    component: () => import('@/view/rent/detail'),
    name: 'rentDetail',
    meta: {
      title: '订单详情'
    }
  },
  {
    path: '/rent/change',
    component: () => import('@/view/rent/change'),
    name: 'rentChange',
    meta: {
      title: '更换设备'
    }
  },
  // /rentDevice
  {
    path: '/rentDevice',
    component: () => import('@/view/rent/rentDevice'),
    name: 'rentDevice',
    meta: {
      title: '租赁设备'
    }
  },
  {
    path: '/deviceDetail',
    component: () => import('@/view/device/detail'),
    name: 'deviceDetail',
    meta: {
      title: '设备信息'
    }
  },

  {
    path: '/rent/pay/test',
    component: () => import('@/view/rent/paytest'),
    name: 'paytest',
    meta: {
      title: '支付测试'
    }
  },
  
];

const router = new Router({ routes, mode: 'history' });

function delCodeandstate(to) { //函数作用：去除url中的code和state
  let path = ""
  for (let i in to.query) {
    if (i != "code" && i != "state") {
      path = path + "&" + i + "=" + to.query[i]
    }
  }
  path = path == "" ? "" : path.substring(1, path.length)
  path = path == "" ? "" : "/?" + path
  return path;
}

router.beforeEach((to, from, next) => {
  document.title = '欧欧眼保仪';
  console.log(to, '-----')
  let fullPath = to.fullPath
  if (to.fullPath.includes("code")) { //判断url中是否有code，踩坑1-页面反复跳转
    fullPath = delCodeandstate(to)
  }
  const domian = window.location.origin //
  // const redirect_uri = encodeURIComponent(domian + fullPath);
  const redirect_uri = domian + fullPath;
  const appid = "wxaa0a17cc6abc08a3" //redirect_uri，授权登陆后的回调地址，需要进行encodeURIComponent处理
  const code = to.query.code, state = to.query.state
  const isCur = process.env.NODE_ENV === 'development';

  const token = sessionStorage.getItem("token") //noLoginArr，白名单，不需要授权登陆的页面  
  if (isCur) { // 微信授权开关
    // sessionStorage.setItem("token", 'Bearer b8a1433be696495db90a055352482560'); // 调试
    sessionStorage.setItem("token", 'Bearer db6eb18eaf064a24a540722777ef8e59')
    // sessionStorage.setItem("userId", '286')
    if(to.name === 'deviceBind' && to.query.sn) {
      getBySN({sn: to.query.sn}).then(res => {
        console.log(res, 'check---deviceSn')        
        if(res === 0) {
          next()
        } else {
          Toast('你已绑定此设备')
          next({
            path: '/',
            replace: true
          })
        }
      }).catch(e => {
        console.log(e, 'to.query.deviceSn')
        next();
      })
    } else {
      next();
    }
  } else {
    if (!token) { //登陆之后获取到code，传到后台登录
      // code && state &&
      let data = {
        code: code,
        type: 31,
        state
      }
      if(code && state) { // code 参数 不存在
        getWxUserByCode(data).then((res) => {
          console.log('wxAuth res====>' , res);
          if (res) {
            sessionStorage.setItem("token", 'Bearer ' + res.accessToken);
            sessionStorage.setItem("openid", res.openid);
            sessionStorage.setItem("userId", res.userId);
            console.log('wxAuth');
            to.query.code = undefined;
            to.query.state = undefined;
            next({...to, replace: true});
          } else {            
            console.log(to.fullPath, delCodeandstate(fullPath), '===');
            console.log(fullPath, delCodeandstate(fullPath), '===');
            // window.location.href = domian + delCodeandstate(fullPath);
            next(false);
          }
        }).catch(function (error) {                  
          console.log(to.fullPath, delCodeandstate(fullPath), '===');
          console.log(fullPath, delCodeandstate(fullPath), '===');
          next(false);
          console.log(error, 'wxAuth error =====');
        });
      } else {
        const redirectUri = (to.name === 'deviceBind' || to.name === 'paytest' || to.name === 'rentDevice' || to.name === 'rentList') ? redirect_uri : domian
        authRedirect({type: 31, redirectUri }).then(r=> {
          console.log(r, 'redirect_uri');
          window.location.href = r;       
        })
      }                 
    } else {
      // ToDo 如果是绑定页面, 校验用户是否已绑定此SN设备
      if(to.name === 'deviceBind' && to.query.sn) {
        getBySN({sn: to.query.sn}).then(res => {
          console.log(res, 'check---deviceSn')        
          if(res === 0) {
            next()
          } else {
            Toast('你已绑定此设备')
            next({
              path: '/',
              replace: true
            })
          }
        }).catch(e => {
          console.log(e, 'to.query.deviceSn')
          next();
        })
      } else {
        next();
      }      
    }
  }
});

export {
  router
};
