import Vue from 'vue';
// import './view/common.css';
import App from './App';
import VueClipboard from 'vue-clipboard2';
import { Toast,Dialog } from 'vant';
import { router } from './router';
import { wxpay } from './utils/wx';
import { wxShare } from './utils/wxShare';
import LyTab from 'ly-tab';

// import Vconsole from 'vconsole'
// const vConsole = new Vconsole()
import Vuex from 'vuex'
Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
		showStep: false,
	},
	mutations:{
		SET_STEP: (state, showStep) => {
			state.showStep = showStep
		}
	},
	actions: {
		setSize({ commit }, showStep) {
			commit('SET_STEP', showStep)
		}
	}
})

Vue.use(LyTab);
// Vue.use(vConsole) // 调试
// 防止el-button重复点击
Vue.directive('preventReClick', {
	inserted(el, binding) {
   	  el.addEventListener('click', () => {
     	if (!el.disabled) {
      	 el.disabled = true
      	 setTimeout(() => {
        	 el.disabled = false
       	}, binding.value || 2000)
     	}
   	})
	 }
});

Vue.use(VueClipboard); //复制
Vue.use(Toast); // 提示
Vue.use(Dialog); // 提示
Vue.use(wxpay);
Vue.use(wxShare);
// Vue.config.ignoredElements = ['wx-open-launch-weapp'];
new Vue({
  router,
	store,
  el: '#app',
  render: h => h(App)
});
